import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Countdown from 'react-countdown';
const WaitingIn = ({ position, time, callback }) => {
    const [TimerAgotado, setTimerAgotado] = useState(false);

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            if (typeof callback === 'function') {
                callback();
            }
            setTimerAgotado(true);
            return null;
        } else {
            // Render a countdown
            return (
                <>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                </>
            );
        }
    };

    return (
        <div id='waitingL'>
            <div className='content'>
                <img
                    src={process.env.REACT_APP_URL_IMAGES + 'cola.svg'}
                    alt='colaIco'
                    className='ico-cola'
                />
                <h1 className=''>Por favor espera,</h1>
                {!TimerAgotado ? (
                    <>
                        <p>
                            Hay alto trafico para este evento, pronto serás
                            habilitado para accesar a comprar tus entradas,
                            estas en el puesto número:
                        </p>

                        <h2>{position}</h2>
                        <i>
                            El tiempo estimado es de{' '}
                            <b>
                                {
                                    <Countdown
                                        date={Date.now() + time * 1000}
                                        renderer={renderer}
                                    />
                                }
                            </b>{' '}
                        </i>
                    </>
                ) : (
                    <>
                        <p>
                            Estamos procesando su pocisión en la cola para
                            permitir la compra.
                        </p>
                    </>
                )}
                <p>
                    Le mostraremos las opciones de compra al llegar su turno.
                    Por favor no cierres esta ventana ni actualice su navegador
                    ya que perderás el puesto en la cola.
                </p>
            </div>
        </div>
    );
};

WaitingIn.propTypes = {
    time: PropTypes.number,
    position: PropTypes.number,
    callback: PropTypes.func
};

export default WaitingIn;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    productos: [],
    loading: false,
    showCar: false,
    productoActivo: null
};

export const carSlice = createSlice({
    name: 'car',
    initialState,
    reducers: {
        setCarInformation: (state, action) => {
            return {
                ...state
            };
        },
        setShowCar: (state, action) => {
            return {
                ...state,
                showCar: action.payload
            };
        },
        add: (state, action) => {
            return {
                ...state,
                productoActivo: null,
                productos: [
                    ...action.payload.car,
                    {
                        ...action.payload.producto,
                        cantidad: action.payload.cantidad,
                        variantes: action.payload.variantes
                    }
                ]
            };
        },
        updateCar: (state, action) => {
            return {
                ...state,
                productoActivo: null,
                productos: [...action.payload]
            };
        },
        removeItem: (state, action) => {
            return {
                ...state,
                productoActivo: null,
                productos: state.productos.filter(
                    (n) => n.id !== action.payload
                )
            };
        },
        clearCar: (state, action) => {
            return {
                ...state,
                productos: []
            };
        },
        openModal: (state, action) => {
            return {
                ...state,
                productoActivo: action.payload.producto
            };
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setCarInformation,
    setShowCar,
    add,
    updateCar,
    openModal,
    removeItem,
    clearCar
} = carSlice.actions;

export default carSlice.reducer;

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import shopReducer from './shopSlice';
import usuarioReducer from './usuarioSlice';
import carReducer from './carSlice';

import storage from 'redux-persist/lib/storage';

import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage
};

const rootReducer = combineReducers({
    shop: shopReducer,
    usuario: usuarioReducer,
    car: carReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer
});

export const persistor = persistStore(store);

import React from 'react';
import { useDispatch } from 'react-redux';
import { removeItem, updateCar } from '../../store/carSlice';

const ButtonMinus = ({ car, id, remove }) => {
    const dispatch = useDispatch();
    const onclick = () => {
        const carUp = car.map((n) => {
            return { ...n };
        });
        let rm = false;
        carUp.map((n) => {
            if (n.id === id && (n.cantidad > 1 || remove) && n.cantidad > 0) {
                n.cantidad--;
                rm = n.cantidad === 0;
            }
            return n;
        });
        if (rm) {
            dispatch(removeItem(id));
        } else {
            dispatch(updateCar(carUp));
        }
    };
    return (
        <i className="icon-minus" onClick={onclick} onKeyDown={() => null} />
    );
};

export default ButtonMinus;

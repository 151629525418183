import React from 'react';
import ImgPlaceholder from '../../assets/images/placeholderImg.svg';
import ButtonMinus from '../car/buttonMinus';
import ButtonPlus from '../car/buttonPlus';
import ButtonAdd from '../car/buttonAdd';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from '../../store/carSlice';

const ProductoItem = ({ item }) => {
    const carStore = useSelector((store) => store.car);
    const inCar = carStore.productos.find((n) => n.id === item.id);
    const dispatch = useDispatch();
    return (
        <>
            <div className="productoItem">
                <figure onClick={() => dispatch(openModal({ producto: item }))}>
                    <img src={item.imgsH[0] || ImgPlaceholder} alt={item.id} />
                </figure>
                <div className="detalle">
                    <div className="nombre textMedium">{item.nombre}</div>
                    <div
                        className="descripcion textRegular"
                        onClick={() => dispatch(openModal({ producto: item }))}
                    >
                        {item.descripcion}
                    </div>
                    <div className="precio textRegular">
                        {item.symbol_currency} {item.precio}
                    </div>
                </div>
                {inCar !== undefined ? (
                    <div className="buttonsCar d-flex">
                        <ButtonMinus
                            car={[...carStore.productos]}
                            id={item.id}
                            remove
                        />
                        <span>{inCar.cantidad}</span>
                        <ButtonPlus
                            car={[...carStore.productos]}
                            id={item.id}
                        />
                    </div>
                ) : (
                    <>
                        {item.agotado ? (
                            <button className="agotado">
                                <span>Agotado</span>
                            </button>
                        ) : item.properties.length > 0 ? (
                            <button
                                className="openVariants buttonAdd"
                                onClick={() => dispatch(openModal({ producto: item }))}
                            >
                                Agregar
                            </button>
                        ) : (
                            <ButtonAdd
                                car={carStore.productos}
                                producto={{
                                    id: item.id,
                                    good_id: item.good_id
                                }}
                                variantes={{}}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default ProductoItem;

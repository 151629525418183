import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './views/Home';
import Help from './views/contactUs';
import Fanbase from './views/fanbase';

import Tienda from './tienda';
import ConfirmarCompra from './tienda/pages/confirmacionCompra';

const App = () => {
    return (
        <main className={`App theme ${process.env.REACT_APP_THEME}`}>
            <Helmet>
                <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png"/>
                <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png"/>
                <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
                <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
                <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
                <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
                <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
                <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
                <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/manifest.json"/>
                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
                <meta name="theme-color" content="#ffffff"/>
                <title>{process.env.REACT_APP_TITLE}</title>
                <meta
                    name='description'
                    content={process.env.REACT_APP_META_DESC}
                />
                <script
                    async
                    src='https://www.googletagmanager.com/gtag/js?id=xxxxxxxxxxxx'
                ></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'xxxxxxxxxxxx');`}
                </script>
            </Helmet>
            <Router>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/help/' element={<Help />} />
                    <Route path='/help/:item' element={<Help />} />
                    <Route path='/tienda/*' element={<Tienda/>} />
                    <Route path='/compraFinalizada' element={<ConfirmarCompra/>} />
                    <Route path='/pricing' element={<Fanbase/>} />
                </Routes>
            </Router>
        </main>
    );
};

export default App;

import React from 'react';
import { useDispatch } from 'react-redux';
import { updateCar } from '../../store/carSlice';

const ButtonPlus = ({ car, id }) => {
    const dispatch = useDispatch();
    const onclick = () => {
        const carUp = car.map((n) => {
            return { ...n };
        });
        carUp.map((n) => {
            if (n.id === id) {
                n.cantidad++;
            }
            return n;
        });
        dispatch(updateCar(carUp));
    };
    return <i className="icon-plus" onClick={onclick} onKeyDown={() => null} />;
};

export default ButtonPlus;

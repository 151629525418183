import React from 'react';
import { useDispatch } from 'react-redux';
import { add } from '../../store/carSlice';

const ButtonAdd = ({
    car,
    producto,
    variantes,
    disabled,
    cantidad,
    label,
    inCar,
    onClick
}) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick();
        } else {
            if (inCar) {
                if (label === 'Modificar') {
                    // dispatch({type:CAR_ACTIONTYPES.UPDATE_PRODUCT,payload:{car,id:inCar.id,cantidad:cantidad || 1}})
                } else {
                    // dispatch({type:CAR_ACTIONTYPES.REMOVE_PRODUCT,payload:{id:inCar.id,car}})
                }
            } else {
                dispatch(
                    add({
                        car,
                        producto,
                        variantes,
                        cantidad: cantidad || 1
                    })
                );
            }
        }
    };
    return (
        <button
            className={`${disabled ? 'disabled' : ''} buttonAdd ${label}`}
            onClick={handleClick}
            disabled={disabled}
            onKeyDown={() => null}
        >
            <span>{label || 'Agregar'}</span>
        </button>
    );
};

export default ButtonAdd;

import React from 'react';
import ProductoItem from './productoItem';
import { formatMonto } from '../utils';

const GridProductos = ({ productos }) => {
    // console.log(productos);
    return (
        <div className="gridProductos">
            <div className="row">
                {productos.map((c) => {
                    return (
                        <div
                            className="col-6 col-md-4 col-lg-3 d-flex"
                            key={c.id}
                        >
                            <ProductoItem item={mapProducto(c)} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default GridProductos;

const mapProducto = (p) => {
    return {
        id: p.id,
        good_id: p.good_id,
        nombre: p.name || '',
        slug: p.id,
        descripcion_corta: p.short_details,
        descripcion: p.details,
        precio: formatMonto(
            p.custom_data ? p.custom_data.conversion.display_price_e2 : 0,
            2,
            ',',
            '.'
        ),
        symbol_currency: p.custom_data ? p.custom_data.conversion.currency.symbol : '',
        iso_currency: p.custom_data ? p.custom_data.conversion.currency.iso : '',
        imgs: p.vertical_picture_urls || [],
        imgsH: p.picture_urls || [],
        agotado: !p.available,
        properties: p.branch_good_properties
            ? p.branch_good_properties.map((p) => {
                  return {
                      ...p,
                      property_options: p.property_options.map((po) => {
                          return {
                              ...po,
                              price_e2: formatMonto(po.price_e2, 2, ',', '.')
                          };
                      })
                  };
              })
            : []
    };
};

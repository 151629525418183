import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Layout from '../components/layout';
import Seo from '../components/seo';

import DetailCarPay from '../components/car/DetailCarPay';
import LoginComponent from '../components/login/loginComponent';
import Lukapay from '../components/car/Lukapay';

import { getProductosCar } from '../services/car';
import { formatMontoR, formatMonto } from '../components/utils';
import Skeleton from '../components/car/skeletonLuka';
import { useNavigate } from 'react-router-dom';
import { setInfoUser } from '../store/usuarioSlice';
// markup
const RealizarPago = () => {
    const [moneda] = useState('USD');
    const [Currencies] = useState(['USD', 'VEF']);
    const [Total, setTotal] = useState(0);
    const [SubTotal, setSubTotal] = useState(0);
    const [Productos, setProductos] = useState([]);
    const [, setMontoGen] = useState(0);
    const [MontoConv, setMontoConv] = useState(0);
    const [, setCantidadProds] = useState(0);
    const [DatosLuka, setDatosLuka] = useState({
        moneda,
        monto: MontoConv
    });

    const dispatch = useDispatch();

    const [showSkeleton, setShowSkeleton] = useState(true);
    const [pagoExitoso, setPagoExitoso] = useState(null);

    const [setErrors] = useState({});
    const [updateLib] = useState(new Date());

    const carStore = useSelector((store) => store.car);
    // const shop = useSelector((store) => store.shop);
    const usuario = useSelector((store) => store.usuario.datos);
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            if (carStore.productos.length > 0) {
                let pArray = [];
                let resp = await getProductosCar(carStore.productos);
                // console.log(resp);
                resp.map((r) => {
                    if (r.status === 200) {
                        pArray.push(r.data);
                    }
                });
                setProductos(pArray || []);
            }
        })();
    }, []);

    useEffect(() => {
        // console.log(Productos);
        if (Productos != null && Productos.length > 0) {
            let acum = 0;
            let cant = 0;
            carStore.productos.map((p) => {
                let producto = Productos.find((n) => n.id === p.id);
                let _tV = getTotalVariantes(producto, p);
                acum +=
                    (parseFloat(
                        formatMonto(
                            producto.custom_data.conversion.display_price_e2,
                            2,
                            ',',
                            '.'
                        )
                    ) +
                        _tV) *
                    p.cantidad;
                cant = cant + p.cantidad;
            });
            setSubTotal(acum);
            setCantidadProds(cant);
            let monto =
                moneda === 'USD' ? acum : acum * parseFloat(8.5).toFixed(2);
            setTotal(formatMontoR(monto, 2, ',', '.'));
            console.log(formatMontoR(monto, 2, ',', '.'));
            setMontoConv(monto);
            setDatosLuka({ monto, moneda });
        }
    }, [Productos, carStore.productos]);

    // CAMBIO DE MONEDA
    useEffect(() => {
        // console.log(Productos);
        if (Productos != null && Productos.length > 0) {
            let acum = 0;
            let cant = 0;
            carStore.productos.map((p) => {
                let producto = Productos.find((n) => n.id === p.id);
                let _tV = getTotalVariantes(producto, p);
                acum +=
                    (parseFloat(
                        formatMonto(
                            producto.custom_data.display_price_e2,
                            2,
                            ',',
                            '.'
                        )
                    ) +
                        _tV) *
                    p.cantidad;
                cant = cant + p.cantidad;
            });
            setSubTotal(acum);
            setCantidadProds(cant);
            let monto =
                moneda === 'USD'
                    ? acum
                    : acum *
                      parseFloat(
                          Currencies.find((n) => n.iso === moneda)
                              .conversion_factor
                      ).toFixed(2);
            setTotal(formatMontoR(monto, 2, ',', '.'));
            setMontoConv(monto);
            setDatosLuka({ monto, moneda });
        }
    }, [moneda]);

    useEffect(() => {
        // if (pagoExitoso && pagoExitoso.Exitoso) {
        // }
    }, [pagoExitoso]);

    const validarDatos = async () => {
        let isValid = true;
        let _E = {};
        if (document.getElementById('email') !== null) {
            return true;
        }

        if (document.getElementById('pais').value === '') {
            _E = { ..._E, errorPais: 'Debe seleccionar un país' };
            isValid = false;
        }
        if (document.getElementById('direccion').value === '') {
            _E = { ..._E, errorDireccion: 'Debe colocar una dirección válida' };
            isValid = false;
        }
        if (document.getElementById('referencia').value === '') {
            _E = {
                ..._E,
                errorReferencia: 'Debe colocar un punto de referencia válido'
            };
            isValid = false;
        }
        setErrors(_E);
        return isValid;
    };

    const procesarPago = async (infoPago) => {
        console.log(infoPago);
        setPagoExitoso(infoPago);
        navigate('/compraFinalizada', {
            state: {
                car: { ...carStore },
                orden: {
                    symbol: moneda,
                    subTotal: SubTotal,
                    total: Total,
                    productos: Productos
                }
            }
        });
    };

    const noEmail = async () => {
        toast.error('Debes iniciar sesión o registrarte para continuar', {
            position: toast.POSITION.TOP_RIGHT
        });
    };
    return (
        <Layout>
            <Seo title={'Shop - Realizar Pago'} />
            <div id="pagePago">
                <section className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <i
                                className="icon-angle-left back"
                                onClick={() => navigate(-1)}
                            ></i>
                            <div className="datos">
                                <h2 className="textSubTitle">Tus datos</h2>
                                <div className="wrapUsuario">
                                    <div className="usuario">
                                        <input
                                            type="hidden"
                                            id="email"
                                            value={
                                                usuario
                                                    ? usuario[0].cliente.Email
                                                    : ''
                                            }
                                        />
                                        <input
                                            type="hidden"
                                            id="name"
                                            value={
                                                usuario
                                                    ? usuario[0].cliente.Nombre
                                                    : ''
                                            }
                                        />
                                        {!usuario ? (
                                            <LoginComponent hideTitle={true} />
                                        ) : (
                                            <div className="datosUser">
                                                <p>
                                                    {usuario[0].cliente.Email}
                                                </p>
                                                <p>
                                                    Ha sido autenticado de
                                                    manera correcta
                                                </p>
                                                <button
                                                    className="btn btn-block btnOutline"
                                                    onClick={() =>
                                                        dispatch(
                                                            setInfoUser(null)
                                                        )
                                                    }
                                                >
                                                    Cambiar de Usuario
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="compra d-md-none mt-5">
                                    <DetailCarPay
                                        updateMonto={setMontoGen}
                                        Productos={Productos}
                                        Total={Total}
                                        SubTotal={SubTotal}
                                        moneda={moneda}
                                        symbol={''}
                                        // setShowMoneda={setShowMoneda}
                                    />
                                </div>
                                {/* <div className="entrega">
                                    <h2 className="textSubTitle">Entrega</h2>
                                    <div className="tipos d-flex">
                                        <div
                                            className={`tipo_encomienda tipo d-flex flex-wrap align-content-center justify-content-center ${
                                                typeEnvio === 'pickup'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                setTypeEnvio('pickup');
                                            }}
                                        >
                                            <i className="icon-location"></i>
                                            <p>Retiro en evento</p>
                                        </div>
                                    </div>
                                    <div className="content row">
                                        <>
                                            <div className="pickup">
                                                <div className="col-12">
                                                    <p>
                                                        <b>
                                                            Dirección de
                                                            entrega:
                                                        </b>
                                                    </p>
                                                    <p>
                                                        Centro comercial El
                                                        Recreo, piso 2, local
                                                        2b-10
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div> */}
                                <div
                                    className="metodosWrap"
                                    style={
                                        usuario
                                            ? {
                                                  opacity: 1,
                                                  pointerEvents: 'auto',
                                                  cursor: 'auto'
                                              }
                                            : {
                                                  opacity: 0.2,
                                                  pointerEvents: 'none',
                                                  cursor: 'not-allowed'
                                              }
                                    }
                                >
                                    { MontoConv > 0 && (
                                        <Lukapay
                                            monto={DatosLuka.monto}
                                            moneda={
                                                process.env.REACT_APP_SHOP_CURRENCY
                                            }
                                            noEmail={noEmail}
                                            update={updateLib}
                                            setShowSkeleton={setShowSkeleton}
                                            showSkeleton={showSkeleton}
                                            procesarPago={procesarPago}
                                            validarDatos={validarDatos}
                                        />
                                    )}
                                    {showSkeleton && <Skeleton />}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-none d-md-flex">
                            <div className="compra">
                                <DetailCarPay
                                    updateMonto={setMontoGen}
                                    Productos={Productos}
                                    Total={Total}
                                    SubTotal={SubTotal}
                                    moneda={moneda}
                                    symbol={''}
                                    // setShowMoneda={setShowMoneda}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default RealizarPago;

const getTotalVariantes = (productoOrigin, pCar) => {
    let _tV = 0;
    Object.keys(pCar.variantes).map((v) => {
        let op = productoOrigin.branch_good_properties
            .find((n) => n.id === parseInt(v.replace('op_', '')))
            ?.property_options.find((n) => n.id === pCar.variantes[v]);
        if (op) {
            _tV += parseFloat(formatMonto(op.price_e2, 2, ',', '.'));
        }
    });
    return _tV;
};

import React, { useState, useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
// import { useLocation } from "@reach/router";
import { getTokenLuka } from '../../services/car';

const Lukapay = ({
    monto,
    procesarPago,
    validarDatos,
    update,
    moneda,
    setShowSkeleton,
    showSkeleton,
    noEmail
}) => {
    // const location = useLocation();
    const [Ready, setReady] = useState(false);
    const [token, setToken] = useState(null);
    const [idTraza, setIdTraza] = useState(null);

    useEffect(() => {
        (async () => {
            let { status, headers } = await getTokenLuka({
                Username: 'tm',
                Password: '12345678'
            });
            // console.log(headers);
            if (status === 200) {
                setToken(headers.token);
                setIdTraza(headers.id);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            token && idTraza && initLuka(monto, false);
        })();
    }, [token, idTraza, update]);
    useEffect(() => {
        (async () => {
            token && idTraza && initLuka(monto, true);
        })();
    }, [moneda]);

    const initLuka = (monto, sk) => {
        window.luka.init(
            token,
            'container',
            {
                monto: parseFloat(monto),
                numeroDecimales: 2,
                separadorMiles: ',',
                separadorDecimal: '.'
            },
            null,
            idTraza,
            {
                moneda,
                terminos: null,
                idioma: 'esp',
                color: '429ff8',
                metodos: 'tdc_bs,tdc_mc,tdd_mc,pm_bod,,zelle',
                paypal: {
                    color: 'gold'
                },
                fuente: 'Open Sans, R-R',
                seleccion: 2,
                showSkeletonLoading: sk,
                fnValidacionEsAsync: true
            },
            function (result, error) {
                if (error && error.Error.length > 0) {
                    console.log('error:', error);
                } else {
                    procesarPago(result);
                }
            },
            async function () {
                return await (async function () {
                    let email = document.getElementById('email').value;
                    console.log(email, email.trim() !== '');
                    if (email.trim() !== '') {
                        window.luka.updateEmail(email, token);
                    } else {
                        noEmail();
                        return false;
                    }

                    return await validarDatos();
                })();
            },
            function () {
                console.log('componente cargado');
                setReady(true);
                setShowSkeleton(false);
            },
            {
                nombrePagador: 'Johan Páez'
            }
        );
    };

    useEffect(() => {
        if (Ready) {
            window.luka.updateMonto(monto, token);
        }
    }, [monto]);

    return (
        <>
            <div
                id="container"
                style={{ display: `${showSkeleton ? 'none' : 'block'}` }}
            ></div>
        </>
    );
};

export default Lukapay;

import http, { getAll } from './http';

export const getProductosCar = async (list) => {
    let arr = list.map((p) => {
        return http.get(`/branch-goods/${p.id}/?with=branch_good_properties`);
        //  return http.get(`/goods/${p.id}/?with=properties`)
    });
    let resp = await getAll(arr);
    return resp;
};
export const getCurrencies = async (branchId) => {
    let resp = await http.get(`/branches/${branchId}/currencies`);
    console.log(resp);
    return resp;
};
export const getTokenLuka = async (body) => {
    let resp = await http.post(
        `https://bspaycoapi-qa.payco.net.ve/api/v1/servicio/login`,
        JSON.stringify(body),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    return resp;
};

export const crearOrden = async (branchId, clientId, token, body) => {
    //  console.log(token);
    let resp = await http.post(
        `/branches/${branchId}/clients/${clientId}/orders`,
        JSON.stringify(body),
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
    );
    console.log(resp);
    return resp;
};

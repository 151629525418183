import React, { useEffect, useState } from 'react';
import './map.scss';

const Mapa = ({
    onClick,
    Color = '',
    closeModal,
    host,
    entradas = null,
    moneda
}) => {
    const [Sections, setSections] = useState([]);
    const [SectionHover, setSectionHover] = useState(null);
    useEffect(() => {
        let blocks = document.querySelectorAll(
            '#arena,#vip,#nba-1,#nba-2,#sector-a,#sector-b,#sector-c,#sector-d,#sector-e,#sector-f,#sector-norte,#sector-sur,#sector-f,#gradas'
        );
        setSections(blocks);
        const scc = document.querySelector('#scroll-svg');
        console.log(scc.getBoundingClientRect().width, scc.scrollWidth);
        scc.scrollTo(
            (scc.scrollWidth - scc.getBoundingClientRect().width) / 2,
            (scc.scrollHeight - scc.getBoundingClientRect().height) / 2
        );
    }, []);
    useEffect(() => {
        if (Sections.length === 0) return;
        Array.from(Sections).map((s, i) => {
            s.addEventListener('mouseenter', hover);
            s.addEventListener('mouseleave', blur);
            if (entradas[s.id].trim().toLowerCase() === 'agotado') {
                s.style.fill = '#9e9e9e';
                s.style.cursor = 'not-allowed';
            }
            s.addEventListener('click', (e) => click(s.id));
            const childern = s.childNodes;
            if (childern) {
                childern.forEach((ch) => {
                    ch.addEventListener('click', (e) => click(s.id));
                });
            }
        });
        return () => {
            Array.from(Sections).map((s, i) => {
                s.removeEventListener('mouseenter', hover);
                s.removeEventListener('mouseleave', blur);
                s.removeEventListener('click', click);
            });
        };
    }, [Sections]);
    const hover = (e) => {
        Array.from(Sections).map((s, i) => {
            if (e.target.id === s.id) {
                s.classList.add('active');
                setSectionHover({
                    ...e.target.dataset,
                    url: entradas[e.target.id]
                });
            }
        });
    };
    const click = (section) => {
        if (
            entradas[section].trim() !== '' &&
            entradas[section].trim().toLowerCase() !== 'agotado'
        ) {
            window.location.href = `${entradas[section]}/?host=${host}`;
        }
    };
    const blur = (e) => {
        Array.from(Sections).map((s, i) => {
            s.classList.remove('active');
            setSectionHover(null);
        });
    };
    return (
        <section id="modalMap">
            <span className="overlay" onClick={() => closeModal()}></span>
            <section id="modalContent">
                <span className="cerrar" onClick={() => closeModal()}>
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x "
                    >
                        <g>
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </g>
                    </svg>
                </span>
                <h3 className="text-center mb-4">Seleccionar zona</h3>
                <div id="scroll-svg">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1316 1316"
                        id="general"
                        data-section="general"
                        data-name=""
                        data-preciobs=""
                        data-preciousd=""
                    >
                        <g id="Capa_2" data-name="Capa 2">
                            <g id="Capa_1-2" data-name="Capa 1">
                                <g id="Layer_9" data-name="Layer 9">
                                    <g id="gradas" data-section="gradas">
                                        <path
                                            className="cls-1"
                                            d="M915.11,7.59l-62,149.58a5.49,5.49,0,0,1-5.07,3.39H719.33a5.49,5.49,0,0,1-5.49-5.49V107a5.49,5.49,0,0,0-5.49-5.49h-101a5.49,5.49,0,0,0-5.49,5.49v48.11a5.49,5.49,0,0,1-5.49,5.49h-131a5.5,5.5,0,0,1-5.08-3.39L398.33,7.59a5.49,5.49,0,0,1,5-7.59H910a5.49,5.49,0,0,1,5.53,5.45A5.59,5.59,0,0,1,915.11,7.59Z"
                                        />
                                        <path
                                            className="cls-1"
                                            d="M439.72,172.72,169.12,443.33a5.48,5.48,0,0,1-6,1.19L13.46,382.62a5.49,5.49,0,0,1-3-7.15,5.57,5.57,0,0,1,1.24-1.85L370,15.33a5.5,5.5,0,0,1,7.78,0A5.6,5.6,0,0,1,379,17.11L441,166.71A5.5,5.5,0,0,1,439.72,172.72Z"
                                        />
                                        <path
                                            className="cls-1"
                                            d="M160.58,467.72V847.07a5.49,5.49,0,0,1-3.4,5.08L7.58,913.61A5.49,5.49,0,0,1,0,908.53V405.47a5.49,5.49,0,0,1,7.6-5.07l149.6,62.25A5.48,5.48,0,0,1,160.58,467.72Z"
                                        />
                                        <path
                                            className="cls-1"
                                            d="M441.7,1149.7l-62,149.56a5.51,5.51,0,0,1-7.18,3,5.45,5.45,0,0,1-1.82-1.22L16.07,946.33a5.48,5.48,0,0,1,1.76-8.94l149-62.54a5.46,5.46,0,0,1,6,1.18l267.66,267.65A5.5,5.5,0,0,1,441.7,1149.7Z"
                                        />
                                        <path
                                            className="cls-1"
                                            d="M908.57,1315.66H401.16a5.49,5.49,0,0,1-5.07-7.6l62.24-149.6a5.51,5.51,0,0,1,5.08-3.38H596.33a5.49,5.49,0,0,1,5.49,5.49V1211a5.49,5.49,0,0,0,5.48,5.5h101a5.49,5.49,0,0,0,5.49-5.49h0v-50.39a5.49,5.49,0,0,1,5.49-5.49H846.62a5.5,5.5,0,0,1,5.08,3.39l62,149.6a5.49,5.49,0,0,1-3,7.17A5.37,5.37,0,0,1,908.57,1315.66Z"
                                        />
                                        <path
                                            className="cls-1"
                                            d="M1298,937.24a5.51,5.51,0,0,1,3,7.18,5.45,5.45,0,0,1-1.22,1.82L1104.2,1141.8,943.09,1302.92a5.49,5.49,0,0,1-9-1.78l-62-149.59a5.51,5.51,0,0,1,1.19-6l117.31-117.22,152-152a5.46,5.46,0,0,1,6-1.19Z"
                                        />
                                        <path
                                            className="cls-1"
                                            d="M1315.66,401.05v512a5.49,5.49,0,0,1-5.49,5.49,5.43,5.43,0,0,1-2.1-.42l-149.58-61.86a5.49,5.49,0,0,1-3.39-5.07V463.82a5.48,5.48,0,0,1,3.37-5.06L1308,396a5.49,5.49,0,0,1,7.62,5.07Z"
                                        />
                                        <path
                                            className="cls-1"
                                            d="M1301.59,382l-150,61.6a5.48,5.48,0,0,1-6-1.2L873,169.71a5.52,5.52,0,0,1-1.19-6L933.88,14.33a5.5,5.5,0,0,1,7.16-3,5.64,5.64,0,0,1,1.84,1.24l360.56,360.55a5.5,5.5,0,0,1-1.85,8.92Z"
                                        />
                                    </g>
                                    <path
                                        id="sector-a"
                                        className="cls-2"
                                        d="M501.33,997.06a5.45,5.45,0,0,1,1.19,6l-11.43,27.6-33.83,81.56a5.51,5.51,0,0,1-7.18,3,5.45,5.45,0,0,1-1.82-1.22L202.45,868.15a5.48,5.48,0,0,1,0-7.75,5.57,5.57,0,0,1,1.77-1.19l81.3-34.13L313,813.56a5.5,5.5,0,0,1,6,1.18l68.16,68.16a5.49,5.49,0,0,1,.16,7.6l-13.87,15.09a5.49,5.49,0,0,0,.16,7.6l30.74,30.68a5.48,5.48,0,0,0,7.74,0l0,0,14.16-14.14a5.48,5.48,0,0,1,7.76,0h0Z"
                                    />
                                    <path
                                        id="sector-c"
                                        className="cls-2"
                                        d="M500.47,319.41l-66.75,66.76a5.49,5.49,0,0,1-7.76,0l-15-15a5.49,5.49,0,0,0-7.76,0l-31.38,31.38a5.5,5.5,0,0,0,0,7.77l15,15a5.5,5.5,0,0,1,0,7.77l-71,70.94a5.48,5.48,0,0,1-6,1.19l-28.79-11.89L200.64,460a5.5,5.5,0,0,1-1.78-9L447.5,202.43a5.5,5.5,0,0,1,9,1.79l33.3,80.4,11.92,28.81A5.48,5.48,0,0,1,500.47,319.41Z"
                                    />
                                    <path
                                        id="sector-b"
                                        className="cls-2"
                                        d="M274.81,637.61v46a5.49,5.49,0,0,0,5.49,5.49h21.49a5.49,5.49,0,0,1,5.49,5.49v92.18a5.51,5.51,0,0,1-3.41,5.08l-29.06,11.94-80.13,32.91a5.49,5.49,0,0,1-7.58-5.08V483.33a5.51,5.51,0,0,1,5.51-5.49,5.73,5.73,0,0,1,2.09.42l80.11,33.34,29.09,12.1a5.48,5.48,0,0,1,3.38,5.07v97.86a5.49,5.49,0,0,1-5.49,5.49H280.33a5.49,5.49,0,0,0-5.52,5.46Z"
                                    />
                                    <path
                                        id="sector-e"
                                        className="cls-2"
                                        d="M1128.58,479.57V835.68a5.49,5.49,0,0,1-5.49,5.49,5.43,5.43,0,0,1-2.1-.42l-86.09-35.62-23.1-9.55a5.49,5.49,0,0,1-3.4-5.08V525.38a5.5,5.5,0,0,1,3.37-5.06l23.13-9.71,86.06-36.1a5.49,5.49,0,0,1,7.19,2.93A5.55,5.55,0,0,1,1128.58,479.57Z"
                                    />
                                    <path
                                        id="sector-d"
                                        className="cls-2"
                                        d="M1115.87,450.12a5.48,5.48,0,0,1,.05,7.76,5.29,5.29,0,0,1-1.85,1.24l-84.82,34.82-24.66,10.13a5.48,5.48,0,0,1-6-1.2L812.05,316.26a5.51,5.51,0,0,1-1.19-6l10.21-24.55,35.14-84.53a5.5,5.5,0,0,1,7.18-3,5.57,5.57,0,0,1,1.77,1.19Z"
                                    />
                                    <path
                                        id="sector-f"
                                        className="cls-2"
                                        d="M1112.91,868.48,865.58,1115.8a5.48,5.48,0,0,1-9-1.78L821,1028.21l-9.69-23.38a5.51,5.51,0,0,1,1.19-6l70.32-70.32a5.48,5.48,0,0,1,7.75,0h0l11.19,11.16a5.5,5.5,0,0,0,7.77,0l32-32a5.52,5.52,0,0,0,0-7.78L930.4,888.76a5.5,5.5,0,0,1,0-7.77l65.7-65.66a5.48,5.48,0,0,1,6-1.19l23.36,9.71,85.68,35.67a5.5,5.5,0,0,1,3,7.18A5.65,5.65,0,0,1,1112.91,868.48Z"
                                    />
                                    <path
                                        id="sector-norte"
                                        className="cls-2"
                                        d="M832.49,187.08a5.49,5.49,0,0,1,5.07,7.6l-34.09,82.25-11.14,26.94a5.5,5.5,0,0,1-5.08,3.39H692.79a5.49,5.49,0,0,1-5.49-5.49V282.42a5.49,5.49,0,0,0-5.49-5.49H633.87a5.49,5.49,0,0,0-5.49,5.49v19.35a5.49,5.49,0,0,1-5.49,5.49H526.11a5.49,5.49,0,0,1-5.07-3.39l-11.15-26.94-34.08-82.25a5.49,5.49,0,0,1,5.07-7.6Z"
                                    />
                                    <path
                                        id="sector-sur"
                                        className="cls-2"
                                        d="M831.06,1128.56H478.94a5.49,5.49,0,0,1-5.07-7.6L508.76,1037l10.51-25.26a5.48,5.48,0,0,1,5.07-3.38h98.55a5.49,5.49,0,0,1,5.49,5.49v17.66a5.49,5.49,0,0,0,5.49,5.49h47.94a5.49,5.49,0,0,0,5.49-5.49v-17.66a5.49,5.49,0,0,1,5.49-5.49h93a5.49,5.49,0,0,1,5.07,3.39L801.33,1037,836.09,1121a5.49,5.49,0,0,1-3,7.18A5.43,5.43,0,0,1,831.06,1128.56Z"
                                    />
                                    <path
                                        className="cls-3"
                                        d="M478.74,806.33V507.64a6.31,6.31,0,0,1,6.51-6.1H834.08a6.33,6.33,0,0,1,6.52,6.1V806.33a6.31,6.31,0,0,1-6.52,6.09H485.25a6.3,6.3,0,0,1-6.51-6.08Z"
                                    />
                                    <path
                                        className="cls-4"
                                        d="M824.25,785.33a6.62,6.62,0,0,1-6.82,6.38H501.91a6.61,6.61,0,0,1-6.82-6.39V527.47a6.61,6.61,0,0,1,6.82-6.38H817.67a6.61,6.61,0,0,1,6.82,6.39Z"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="659.25"
                                        y1="521.09"
                                        x2="659.25"
                                        y2="791.69"
                                    />
                                    <ellipse
                                        className="cls-5"
                                        cx="660.27"
                                        cy="656.07"
                                        rx="27.86"
                                        ry="26.07"
                                    />
                                    <ellipse
                                        className="cls-6"
                                        cx="569.59"
                                        cy="656.07"
                                        rx="27.86"
                                        ry="26.07"
                                    />
                                    <path
                                        className="cls-5"
                                        d="M495.26,765h35.49s40.64-6.92,63.49-59c0,0,19-53.76-2.79-104.66,0,0-14.32-40.72-60.7-53.35H495.26Z"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="495.26"
                                        y="630"
                                        width="74.34"
                                        height="52.14"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="521.79"
                                        y1="625.94"
                                        x2="521.79"
                                        y2="630.01"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="521.79"
                                        y1="682.14"
                                        x2="521.79"
                                        y2="687.03"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="534.4"
                                        y1="625.94"
                                        x2="534.4"
                                        y2="630.01"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="534.4"
                                        y1="682.14"
                                        x2="534.4"
                                        y2="687.03"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="547.04"
                                        y1="625.94"
                                        x2="547.04"
                                        y2="630.01"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="547.04"
                                        y1="682.14"
                                        x2="547.04"
                                        y2="687.03"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="559.65"
                                        y1="625.94"
                                        x2="559.65"
                                        y2="630.01"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="559.65"
                                        y1="682.14"
                                        x2="559.65"
                                        y2="687.03"
                                    />
                                    <ellipse
                                        className="cls-6"
                                        cx="749.9"
                                        cy="656.07"
                                        rx="27.86"
                                        ry="26.07"
                                    />
                                    <path
                                        className="cls-5"
                                        d="M824.24,765H788.75s-40.65-6.92-63.5-59c0,0-19-53.76,2.8-104.66,0,0,14.31-40.72,60.7-53.35h35.49Z"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="749.9"
                                        y="630"
                                        width="74.34"
                                        height="52.14"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="797.71"
                                        y1="625.94"
                                        x2="797.71"
                                        y2="630.01"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="797.71"
                                        y1="682.14"
                                        x2="797.71"
                                        y2="687.03"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="785.09"
                                        y1="625.94"
                                        x2="785.09"
                                        y2="630.01"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="785.09"
                                        y1="682.14"
                                        x2="785.09"
                                        y2="687.03"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="772.46"
                                        y1="625.94"
                                        x2="772.46"
                                        y2="630.01"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="772.46"
                                        y1="682.14"
                                        x2="772.46"
                                        y2="687.03"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="759.84"
                                        y1="625.94"
                                        x2="759.84"
                                        y2="630.01"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="759.84"
                                        y1="682.14"
                                        x2="759.84"
                                        y2="687.03"
                                    />
                                    <g id="arena">
                                        <path
                                            id="a1"
                                            d="M518.52,428.56H795a11.32,11.32,0,0,0,10.45-15.65l-25.81-62.39-6.26-15.08a11.31,11.31,0,0,0-10.46-7H551a11.33,11.33,0,0,0-10.44,6.93l-6.35,15.14-26.15,62.34a11.32,11.32,0,0,0,10.43,15.71Z"
                                        />
                                        <path d="M507.56,886H805.85a4.6,4.6,0,0,1,4.25,6.36l-29.84,72.11-8,19.35a4.61,4.61,0,0,1-4.25,2.85H545.94a4.59,4.59,0,0,1-4.24-2.82l-8.13-19.38-30.24-72.09a4.6,4.6,0,0,1,2.46-6A4.48,4.48,0,0,1,507.56,886Z" />
                                        <rect
                                            x="338.66"
                                            y="530.69"
                                            width="111.95"
                                            height="259.41"
                                            rx="6.88"
                                        />
                                        <rect
                                            x="865.58"
                                            y="530.69"
                                            width="111.95"
                                            height="259.41"
                                            rx="6.88"
                                        />
                                    </g>
                                    <rect
                                        id="nba-1"
                                        className="cls-7"
                                        x="475.24"
                                        y="435.99"
                                        width="172.02"
                                        height="55.28"
                                        rx="8.06"
                                    />
                                    <path
                                        id="nba-2"
                                        className="cls-1"
                                        d="M680.33,436h154.9a8.06,8.06,0,0,1,8.06,8.06h0v39.16a8.06,8.06,0,0,1-8.06,8.06H679.33a8.06,8.06,0,0,1-8.06-8.06V445.05A9.06,9.06,0,0,1,680.33,436Z"
                                    />
                                    <rect
                                        id="vip"
                                        className="cls-8"
                                        x="475.38"
                                        y="823.77"
                                        width="367.95"
                                        height="50.92"
                                        rx="8.17"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M549.84,241c-.8,3.75-3.63,6.47-8.66,6.47-6.37,0-9.38-4.5-9.38-10.22,0-5.5,3.11-10.43,9.59-10.43,5.35,0,7.94,3.14,8.43,6.49h-4.34a3.94,3.94,0,0,0-4.23-3.09c-3.58,0-5,3.22-5,6.88,0,3.39,1.2,7,5.08,7a4,4,0,0,0,4.11-3.07Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M567,238.51H557.4v5.1H568l-.52,3.56H553.19V227.09h14.24v3.56h-10V235H567Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M571.1,247.17V227.09h5.38c2.51,4.51,7.15,12.64,8.07,14.83h.06c-.23-2.1-.23-5.59-.23-8.88v-5.95h4v20.08h-5.1c-2.16-3.92-7.3-13.27-8.29-15.59h-.06c.15,1.76.19,5.89.19,9.48v6.11Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M597.4,230.65h-6.07v-3.56h16.52v3.56h-6.08v16.52h-4.34Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M614.76,239.13v8h-4.25v-20h8.82c4.38,0,6.73,2.41,6.73,5.51a4.81,4.81,0,0,1-2.91,4.75c1,.37,2.49,1.4,2.49,5v1a15.89,15.89,0,0,0,.29,3.83h-4.12a14.26,14.26,0,0,1-.42-4.51v-.34c0-2.2-.55-3.24-3.79-3.24Zm0-3.45h3.64c2.37,0,3.29-.91,3.29-2.59s-1.07-2.55-3.19-2.55h-3.74Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M634.06,242.53l-1.57,4.64h-4.27L635,227.09h5.45l7.12,20.08H643l-1.65-4.64Zm6.4-3.56c-1.43-4.24-2.34-6.94-2.82-8.75h0c-.49,2-1.5,5.13-2.64,8.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M650.19,227.09h4.34v16.52h10l-.49,3.56H650.19Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M674.33,247.17V227.09h5.38c2.51,4.51,7.14,12.64,8.07,14.83h.05c-.23-2.1-.22-5.59-.22-8.88v-5.95h3.93v20.08h-5.1c-2.16-3.92-7.3-13.27-8.29-15.59h-.06c.15,1.76.2,5.89.2,9.48v6.11Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M714.79,237.06c0,5.59-3.36,10.4-9.88,10.4-6.31,0-9.6-4.58-9.6-10.33s3.64-10.33,9.88-10.33C711.06,226.8,714.79,230.91,714.79,237.06Zm-15,0c0,3.92,1.72,6.89,5.28,6.89,3.86,0,5.23-3.24,5.23-6.82,0-3.79-1.56-6.78-5.3-6.78s-5.21,2.78-5.21,6.69Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M722.83,239.13v8h-4.25v-20h8.83c4.38,0,6.73,2.41,6.73,5.51a4.79,4.79,0,0,1-2.92,4.75c1,.37,2.5,1.4,2.5,5v1a15.89,15.89,0,0,0,.29,3.83h-4.12a14.26,14.26,0,0,1-.42-4.51v-.34c0-2.2-.55-3.24-3.79-3.24Zm0-3.45h3.64c2.37,0,3.29-.91,3.29-2.59s-1.07-2.55-3.19-2.55h-3.74Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M742.23,230.65h-6.1v-3.56h16.52v3.56h-6.08v16.52h-4.34Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M769.16,238.51h-9.6v5.1h10.59l-.51,3.56H755.33V227.09h14.24v3.56h-10V235h9.6Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M645.9,381.52l-1.57,4.64h-4.27l6.82-20.09h5.45l7.11,20.09h-4.56l-1.65-4.64Zm6.4-3.56c-1.43-4.24-2.33-6.95-2.82-8.75h0c-.49,2-1.5,5.13-2.64,8.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M661,386.16v-.27c0-3.34,1.5-6.05,5.65-9.08,2.7-2,3.52-2.66,3.52-4.33s-.8-2.6-2.42-2.6c-1.88,0-2.55,1.15-2.82,2.82h-4c.27-3.3,2.44-6.06,6.84-6.06,4.93,0,6.61,2.93,6.61,5.8s-1.2,4.14-4.73,6.62c-2.36,1.66-3.31,2.75-3.63,3.62h9.07l-.52,3.48Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M524.63,475.07V455H530c2.51,4.51,7.15,12.64,8.07,14.83h.06c-.23-2.1-.23-5.58-.23-8.88V455h3.94v20.08h-5.11c-2.15-3.92-7.29-13.26-8.29-15.58h0c.15,1.75.19,5.88.19,9.47v6.11Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M546.65,455h8.78c4.85,0,6.69,2.53,6.69,5.14a4.56,4.56,0,0,1-2.56,4.35,4.78,4.78,0,0,1,3.13,4.58c0,3.44-2.69,6-7,6h-9.07Zm8.28,7.92c2.06,0,2.91-.92,2.91-2.34s-1.11-2.24-2.79-2.24h-4.13v4.57Zm-4,8.81h3.91c2.35,0,3.41-1,3.41-2.8,0-1.62-1-2.65-3.43-2.65h-3.89Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M570.2,470.43l-1.57,4.64h-4.3L571.15,455h5.45l7.12,20.08h-4.57l-1.65-4.64Zm6.4-3.56c-1.43-4.23-2.34-6.94-2.82-8.75h0c-.5,2-1.5,5.13-2.64,8.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M597.86,455v20.08h-4.34V455Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M639.94,861.85l-7.09-20.08h4.67l3,9.28c.91,2.8,1.6,5.18,2.06,7.24h.06c.41-1.88,1.2-4.33,2.15-7.18l3.13-9.34h4.41l-7.28,20.08Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M659.11,841.77v20.08h-4.34V841.77Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M663.91,841.77h8.31c4.27,0,7.17,2.4,7.17,6.22,0,4.36-3.31,6.36-7.27,6.36h-3.86v7.5h-4.35Zm4.35,9.13h3.31c2,0,3.43-.78,3.43-2.83s-1.49-2.84-3.34-2.84h-3.4Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M716.14,475.07V455h5.38c2.51,4.51,7.15,12.64,8.07,14.83h0c-.22-2.1-.22-5.58-.22-8.88V455h4v20.08h-5.11c-2.16-3.92-7.29-13.26-8.29-15.58h0c.14,1.75.19,5.88.19,9.47v6.11Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M738.16,455h8.77c4.86,0,6.7,2.53,6.7,5.14a4.56,4.56,0,0,1-2.56,4.35,4.78,4.78,0,0,1,3.13,4.58c0,3.44-2.7,6-7,6h-9.07Zm8.28,7.92c2.06,0,2.91-.92,2.91-2.34s-1.11-2.23-2.79-2.23h-4.14v4.57Zm-4,8.81h3.89c2.35,0,3.41-1,3.41-2.8,0-1.62-1-2.65-3.43-2.65h-3.9Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M761.7,470.43l-1.56,4.64h-4.27L762.69,455h5.45l7.12,20.08h-4.57L769,470.43Zm6.41-3.56c-1.43-4.23-2.34-6.94-2.82-8.75h0c-.5,2-1.5,5.13-2.64,8.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M789.33,455v20.08H785V455Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M798.52,455v20.08h-4.34V455Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M648.33,940.23l-1.57,4.63h-4.27l6.82-20.08h5.45l7.12,20.08h-4.57l-1.65-4.63Zm6.4-3.56c-1.43-4.24-2.34-7-2.82-8.76h0c-.49,2-1.5,5.14-2.64,8.76Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M666.9,944.86V930.53a12.58,12.58,0,0,1-3.47.84v-3.12a8.94,8.94,0,0,0,4.75-2.57h3v19.18Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M382.65,662.15l-1.57,4.64h-4.27l6.82-20.09h5.46l7.11,20.09h-4.56L390,662.15Zm6.4-3.56c-1.43-4.24-2.33-7-2.82-8.75h0c-.49,2-1.5,5.13-2.64,8.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M397.75,652.55c.27-2.36,1.93-5.28,6.73-5.28,4.44,0,6.41,2.51,6.41,5.22a4,4,0,0,1-2.37,3.89v.08a4.59,4.59,0,0,1,3.11,4.62c0,2.78-1.68,6.06-7.19,6.06-5.13,0-6.88-3.1-7.07-5.64h4.05a2.89,2.89,0,0,0,3.14,2.44,2.67,2.67,0,0,0,2.88-2.46,1.85,1.85,0,0,0,0-.47c0-1.77-.91-2.84-3.14-2.84h-1.73V655h1.52c1.91,0,2.69-.64,2.69-2.24,0-1.32-.79-2.21-2.44-2.21a2.32,2.32,0,0,0-2.62,2v0Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M909.6,662.15,908,666.79h-4.27l6.82-20.09H916l7.12,20.09h-4.57l-1.65-4.64Zm6.41-3.56c-1.43-4.24-2.34-7-2.82-8.75h0c-.5,2-1.5,5.13-2.64,8.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M933.11,666.79v-4.31h-8.67v-3.64l8-11.22H937v11.49h2.35l-.38,3.37H937v4.31Zm0-11.1c0-2.07,0-3.23.09-4.44a85.41,85.41,0,0,1-5.06,7.86h5Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M316.15,416c1.77,1.08,3.41.67,5-1s1.66-3,.61-4-2.36-1-5.33.95c-4.73,3-7.1,2.6-9,.71-2.51-2.44-2.38-6,.94-9.38,3.72-3.82,7.25-3.34,9.56-1.5l-3.06,3.14c-1-.72-2.47-1.16-4.27.69-1.23,1.26-1.45,2.53-.43,3.53s2,.76,4.8-1c5.07-3.22,7.51-2.62,9.54-.65,2.63,2.56,2.49,6.31-1.21,10.11s-7.23,3.82-10.19,1.61Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M334.41,393.27l-6.71,6.88,3.66,3.56,7.39-7.59,2.19,2.86-10,10.23-14.39-14,9.94-10.2,2.55,2.48-7,7.18,3.08,3,6.71-6.88Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M351.16,379.63c2.12,3.19,2.1,7.12-1.41,10.72-4.45,4.56-9.77,3.57-13.86-.42s-5.31-9.51-.79-14.15c3.74-3.83,7.79-3.49,10.54-1.5l-3,3.11a4,4,0,0,0-5.17.87c-2.5,2.56-1.15,5.8,1.46,8.35,2.43,2.37,5.83,4,8.54,1.23a4,4,0,0,0,.67-5.08Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M349.18,366.86l-4.26,4.37-2.59-2.49,11.54-11.83,2.55,2.48-4.25,4.36L364,375.33l-3,3.11Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M375.39,349.16c4,3.91,5.1,9.67.55,14.34-4.4,4.51-10,3.67-14.1-.34s-4.86-9.82-.5-14.29C365.44,344.66,371,344.87,375.39,349.16ZM364.9,359.89c2.81,2.73,6.14,3.57,8.62,1,2.7-2.76,1.34-6-1.23-8.5-2.72-2.65-5.94-3.62-8.56-.94S362.1,357.16,364.9,359.89Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M382.48,344.85l5.76,5.61-3,3-14.39-14,6.16-6.32c3.06-3.13,6.43-3.14,8.65-1a4.82,4.82,0,0,1,1.37,5.41c1-.46,2.75-.81,5.32,1.7l.71.69a17,17,0,0,0,2.94,2.47l-2.87,2.94a14,14,0,0,1-3.52-2.84l-.21-.21c-1.57-1.53-2.71-1.86-5,.46ZM380,342.44l2.54-2.61c1.66-1.7,1.65-3,.45-4.16s-2.57-1-4,.51l-2.67,2.68Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M411.12,318.12c2.12,3.19,2.1,7.12-1.41,10.72-4.44,4.56-9.77,3.57-13.86-.42s-5.31-9.51-.78-14.15c3.73-3.83,7.79-3.49,10.53-1.5l-3,3.11a3.93,3.93,0,0,0-5.16.87c-2.5,2.56-1.16,5.8,1.46,8.35,2.43,2.37,5.83,4,8.53,1.23a4,4,0,0,0,.67-5.09Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M895.44,317.68c-1.09,1.75-.68,3.4,1,5s3,1.68,4,.63,1-2.34-.92-5.33c-3-4.75-2.55-7.11-.64-9,2.46-2.49,6-2.34,9.38,1,3.79,3.75,3.28,7.28,1.42,9.57l-3.11-3.07c.72-1,1.18-2.47-.66-4.29-1.26-1.23-2.52-1.46-3.53-.45s-.77,2,1,4.81c3.18,5.09,2.57,7.53.58,9.55-2.58,2.6-6.33,2.44-10.1-1.29s-3.77-7.26-1.54-10.2Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M918,336.09l-6.83-6.75L907.6,333l7.53,7.45-2.87,2.16-10.16-10,14.12-14.29,10.12,10-2.5,2.53-7.13-7.05-3,3.06,6.83,6.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M931.54,352.94c-3.21,2.1-7.14,2.05-10.71-1.48-4.53-4.48-3.5-9.8.51-13.87,3.87-3.91,9.55-5.23,14.16-.68,3.8,3.76,3.44,7.82,1.43,10.55l-3.09-3a3.94,3.94,0,0,0-.83-5.17c-2.55-2.52-5.8-1.2-8.37,1.4-2.39,2.41-4,5.8-1.29,8.52a4,4,0,0,0,5.08.71Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M944.33,351.05,940,346.76l2.51-2.53,11.75,11.62-2.51,2.53-4.32-4.27L935.8,365.86l-3.09-3.06Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M961.83,377.38c-3.93,4-9.7,5-14.34.45-4.48-4.43-3.6-10,.44-14.09s9.86-4.79,14.29-.4C966.4,367.47,966.15,373,961.83,377.38Zm-10.65-10.55c-2.76,2.79-3.62,6.1-1.09,8.61,2.75,2.71,6,1.38,8.51-1.17,2.67-2.7,3.67-5.92,1-8.55S953.93,364.05,951.18,366.83Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M966.09,384.51l-5.65,5.72-3-3L971.56,373l6.28,6.21c3.11,3.08,3.09,6.45.91,8.66a4.81,4.81,0,0,1-5.42,1.32c.45,1,.79,2.76-1.74,5.31l-.69.7a16.23,16.23,0,0,0-2.49,2.93l-2.93-2.89a13.84,13.84,0,0,1,2.87-3.5l.21-.21c1.54-1.57,1.88-2.69-.42-5Zm2.43-2.45,2.59,2.56c1.69,1.67,3,1.67,4.16.47s1-2.56-.48-4l-2.66-2.63Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M990.26,391.48l5.67,5.6c4.15,4.11,4.16,9.54,0,13.76s-9.88,4.71-14.25.39l-5.53-5.47Zm-8.56,14.85,2.31,2.29c2.74,2.71,6,1.87,8.71-.91,2.31-2.34,3.29-5.6.5-8.37l-2.33-2.3Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M312.15,917.8c-1.11,1.75-.72,3.39.92,5s3,1.71,4,.67,1-2.33-.87-5.33c-2.94-4.79-2.48-7.15-.55-9.06,2.48-2.46,6-2.28,9.36,1.1,3.76,3.78,3.22,7.31,1.34,9.58l-3.08-3.1c.72-1,1.2-2.45-.63-4.29-1.24-1.25-2.5-1.5-3.52-.49s-.79,2,1,4.82c3.14,5.12,2.5,7.56.49,9.55-2.61,2.58-6.35,2.38-10.08-1.38s-3.7-7.3-1.44-10.22Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M334.54,936.43l-6.76-6.82-3.63,3.6,7.47,7.52-2.89,2.14-10.07-10.15,14.26-14.14,10,10.1-2.52,2.51-7.07-7.11-3.06,3,6.77,6.81Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M347.9,953.41c-3.23,2.06-7.15,2-10.69-1.59-4.49-4.52-3.41-9.83.65-13.86,3.9-3.87,9.59-5.14,14.16-.54,3.76,3.79,3.36,7.84,1.32,10.56l-3-3.09a3.93,3.93,0,0,0-.79-5.17c-2.52-2.55-5.78-1.26-8.37,1.32-2.41,2.39-4.11,5.76-1.37,8.51a4,4,0,0,0,5.07.76Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M360.71,951.64l-4.3-4.33,2.52-2.51,11.64,11.73L368.05,959l-4.28-4.31L352,966.33,349,963.25Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M378,978.14c-4,3.94-9.75,4.94-14.34.31-4.44-4.47-3.51-10,.57-14.09s9.9-4.69,14.3-.26C382.62,968.27,382.33,973.81,378,978.14Zm-10.55-10.66c-2.78,2.76-3.68,6.07-1.17,8.6,2.72,2.74,6,1.43,8.52-1.09,2.7-2.67,3.72-5.88,1.09-8.54s-5.66-1.72-8.44,1Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M382.15,985.33l-5.7,5.66-3-3,14.25-14.14,6.22,6.26c3.08,3.11,3,6.48.83,8.67a4.79,4.79,0,0,1-5.43,1.27c.44,1,.76,2.76-1.79,5.3l-.7.69a15.55,15.55,0,0,0-2.52,2.91l-2.9-2.93a14.25,14.25,0,0,1,2.91-3.47l.21-.21c1.56-1.54,1.9-2.67-.38-5Zm2.46-2.44,2.56,2.59c1.67,1.68,3,1.7,4.15.51s1.06-2.55-.43-4.06l-2.64-2.65Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M398,1006.05l-4.4,2.16-3-3,19.06-9.31,3.84,3.87-9.24,19.2-3.22-3.24,2.13-4.44Zm7,2c2-4,3.29-6.55,4.23-8.16h0c-1.76,1-4.7,2.54-8.07,4.28Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M917.52,1008.05c1.77,1.06,3.41.62,5-1.06s1.62-3,.56-4-2.37-.92-5.32,1c-4.7,3.07-7.07,2.67-9,.8-2.53-2.42-2.44-6,.84-9.39,3.69-3.86,7.22-3.41,9.55-1.59l-3,3.16c-1-.7-2.49-1.13-4.27.74-1.22,1.27-1.43,2.55-.4,3.53s2,.74,4.8-1.09c5-3.27,7.48-2.7,9.53-.74,2.65,2.53,2.55,6.28-1.11,10.11s-7.19,3.9-10.17,1.72Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M935.54,985.16l-6.63,7,3.69,3.52,7.31-7.66,2.22,2.83-9.87,10.34-14.53-13.88L927.57,977l2.57,2.45-6.92,7.25,3.11,3,6.64-7Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M952.15,971.33c2.15,3.17,2.17,7.09-1.3,10.73-4.4,4.61-9.74,3.67-13.87-.28-4-3.8-5.4-9.45-.92-14.14,3.69-3.87,7.75-3.57,10.52-1.61l-3,3.14a4,4,0,0,0-5.16.92c-2.47,2.59-1.1,5.82,1.55,8.34,2.45,2.35,5.87,4,8.55,1.14a4,4,0,0,0,.62-5.09Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M950,958.6,945.83,963l-2.58-2.46,11.42-12,2.57,2.46L953,955.4l12,11.42L962,970Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M976.06,940.63c4,3.86,5.21,9.61.7,14.33-4.35,4.56-9.94,3.78-14.1-.19s-4.95-9.77-.65-14.28C966.07,936.24,971.62,936.39,976.06,940.63Zm-10.37,10.84c2.84,2.7,6.17,3.51,8.63.93,2.67-2.79,1.27-6-1.31-8.49-2.75-2.62-6-3.56-8.57-.85S962.87,948.76,965.69,951.47Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M983.12,936.25l5.81,5.55L986,944.87,971.47,931l6.1-6.38c3-3.17,6.4-3.21,8.64-1.06a4.8,4.8,0,0,1,1.42,5.39c1-.47,2.74-.84,5.34,1.65l.71.68a16.34,16.34,0,0,0,3,2.44l-2.84,3a14,14,0,0,1-3.55-2.81l-.21-.2c-1.59-1.52-2.73-1.84-5,.51Zm-2.5-2.39,2.51-2.63c1.64-1.72,1.62-3,.41-4.17s-2.58-1-4,.55L977,930.32Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M989.66,912l9.68-10.14,2.58,2.46-6.76,7.07,3.56,3.4,6.33-6.62,2.57,2.45-6.32,6.63,5.81,5.55-2.92,3.07Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M1074.07,725.53c2-.45,2.9-1.9,2.9-4.23s-.92-3.31-2.39-3.31c-1.63,0-2.34,1-3.14,4.41-1.28,5.46-3.27,6.81-6,6.81-3.5,0-5.88-2.61-5.88-7.37,0-5.33,2.87-7.45,5.8-7.74v4.37c-1.23.21-2.58.9-2.58,3.48,0,1.77.73,2.83,2.16,2.83s2-.85,2.72-4.1c1.37-5.84,3.54-7.12,6.37-7.12,3.67,0,6.19,2.78,6.19,8.08,0,5.1-2.51,7.79-6.17,8.27Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M1071.33,696.54v9.6h5.1V695.55l3.56.51v14.27h-20.08V696.12h3.56v10h4.3v-9.6Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M1073.76,675.07c3.75.81,6.48,3.64,6.48,8.66,0,6.37-4.51,9.38-10.22,9.38-5.51,0-10.44-3.1-10.44-9.58,0-5.35,3.14-7.94,6.5-8.43v4.34a3.94,3.94,0,0,0-3.1,4.22c0,3.59,3.23,5,6.88,5,3.4,0,7-1.2,7-5.08a4,4,0,0,0-3.07-4.11Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M1063.43,667.33v6.1h-3.56V656.89h3.56V663H1080v4.37Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M1069.84,636.33c5.59,0,10.4,3.35,10.4,9.88,0,6.3-4.58,9.6-10.33,9.6s-10.33-3.65-10.33-9.88C1059.58,640.07,1063.7,636.33,1069.84,636.33Zm0,15c3.93,0,6.89-1.72,6.89-5.28,0-3.86-3.23-5.23-6.81-5.23-3.8,0-6.79,1.56-6.79,5.3s2.78,5.21,6.69,5.21Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M1071.92,628.33h8v4.24h-20.08v-8.84c0-4.38,2.41-6.74,5.51-6.74a4.81,4.81,0,0,1,4.76,2.92c.36-1,1.4-2.5,5-2.5h1a15.89,15.89,0,0,0,3.83-.29v4.12a14.26,14.26,0,0,1-4.51.42h-.29c-2.2,0-3.23.55-3.23,3.79Zm-3.46,0v-3.64c0-2.38-.91-3.3-2.58-3.3s-2.55,1.07-2.55,3.19v3.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M1071.33,592.4V602h5.1V591.41l3.56.51v14.3h-20.08V592h3.56v10h4.3v-9.6Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M239.56,726.33c2-.46,2.9-1.9,2.9-4.23s-.91-3.31-2.38-3.31c-1.64,0-2.35,1-3.15,4.41-1.28,5.46-3.27,6.81-6,6.81-3.5,0-5.88-2.62-5.88-7.37,0-5.33,2.87-7.46,5.81-7.74v4.43c-1.24.21-2.58.9-2.58,3.48,0,1.77.73,2.83,2.16,2.83s2-.85,2.71-4.1c1.38-5.84,3.55-7.12,6.38-7.12,3.67,0,6.18,2.77,6.18,8.08,0,5.1-2.51,7.79-6.17,8.27Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M236.79,697.33v9.6h5.1v-10.6l3.56.52v14.29H225.33v-14.2h3.56v10h4.3v-9.61Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M239.26,675.9c3.74.81,6.47,3.64,6.47,8.66,0,6.37-4.5,9.38-10.22,9.38-5.5,0-10.44-3.1-10.44-9.58,0-5.35,3.15-8,6.5-8.44v4.35a3.93,3.93,0,0,0-3.09,4.22c0,3.59,3.22,5,6.88,5,3.39,0,7-1.2,7-5.08a4,4,0,0,0-3.07-4.11Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M228.93,668.14v6.1h-3.6V657.71h3.56v6.08h16.52v4.35Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M235.33,637.18c5.59,0,10.4,3.35,10.4,9.87,0,6.31-4.58,9.61-10.33,9.61s-10.34-3.65-10.34-9.89C225.07,640.9,229.19,637.18,235.33,637.18Zm0,15c3.92,0,6.89-1.72,6.89-5.28,0-3.86-3.24-5.23-6.82-5.23-3.8,0-6.78,1.56-6.78,5.3s2.79,5.2,6.71,5.2Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M237.41,629.14h8v4.24H225.33v-8.83c0-4.37,2.41-6.73,5.51-6.73a4.83,4.83,0,0,1,4.75,2.92c.37-1,1.4-2.5,5-2.5h1a15.89,15.89,0,0,0,3.83-.29v4.12a14.26,14.26,0,0,1-4.51.42h-.29c-2.2,0-3.24.55-3.24,3.79Zm-3.45,0v-3.65c0-2.37-.91-3.29-2.59-3.29s-2.55,1.07-2.55,3.19v3.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M225.33,607.05v-8.78c0-4.85,2.53-6.69,5.13-6.69a4.58,4.58,0,0,1,4.36,2.56A4.78,4.78,0,0,1,239.4,591c3.43,0,6,2.69,6,7v9.07Zm7.92-8.28c0-2.06-.93-2.91-2.34-2.91s-2.23,1.11-2.23,2.79v4.14h4.65Zm8.8,4v-3.92c0-2.35-1-3.41-2.8-3.41-1.61,0-2.65,1-2.65,3.43v3.9Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M570.07,1083.4c-.8,3.75-3.63,6.48-8.66,6.48-6.36,0-9.38-4.51-9.38-10.22,0-5.51,3.11-10.44,9.59-10.44,5.35,0,7.94,3.14,8.43,6.49h-4.34a4,4,0,0,0-4.23-3.09c-3.58,0-5,3.22-5,6.88,0,3.39,1.2,7,5.08,7a4,4,0,0,0,4.11-3.07Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M587.24,1080.93h-9.61v5.1h10.6l-.52,3.56H573.42v-20.08h14.24v3.56h-10v4.3h9.61Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M591.33,1089.59v-20.08h5.39c2.5,4.51,7.14,12.64,8.06,14.83h.06c-.23-2.1-.23-5.58-.23-8.88v-6h3.94v20.08h-5.1c-2.16-3.92-7.3-13.26-8.29-15.58h-.06c.15,1.75.19,5.88.19,9.47v6.11Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M617.63,1073.07h-6.1v-3.56h16.52v3.56H622v16.52h-4.34Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M635,1081.55v8h-4.25v-20.08h8.83c4.38,0,6.73,2.41,6.73,5.51a4.79,4.79,0,0,1-2.91,4.75c1,.37,2.49,1.41,2.49,5v1a15.89,15.89,0,0,0,.29,3.83h-4.12a14.26,14.26,0,0,1-.42-4.51v-.29c0-2.2-.55-3.24-3.79-3.24Zm0-3.45h3.64c2.38,0,3.29-.91,3.29-2.59s-1.07-2.54-3.19-2.54H635Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M654.33,1085l-1.57,4.64h-4.27l6.82-20.08h5.45l7.12,20.08h-4.57l-1.65-4.64Zm6.4-3.56c-1.43-4.23-2.34-6.94-2.82-8.75h0c-.49,2-1.5,5.13-2.64,8.75Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M670.42,1069.51h4.34V1086h10l-.49,3.56H670.42Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M697.48,1083.7c.46,2,1.9,2.91,4.23,2.91s3.31-.92,3.31-2.39c0-1.63-1-2.34-4.41-3.14-5.46-1.29-6.81-3.27-6.81-6,0-3.51,2.62-5.88,7.37-5.88,5.33,0,7.46,2.86,7.74,5.8h-4.37c-.21-1.23-.9-2.58-3.48-2.58-1.77,0-2.83.73-2.83,2.16s.85,2,4.1,2.71c5.84,1.38,7.12,3.55,7.12,6.38,0,3.67-2.77,6.19-8.08,6.19-5.1,0-7.79-2.51-8.27-6.18Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M716.71,1069.51v12c0,3.85,2,5,4.14,5,2.49,0,4-1.35,4-5v-12h4.34v11.75c0,6.87-4,8.62-8.44,8.62s-8.47-1.83-8.47-8.55V1069.5Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="M738.08,1081.55v8h-4.25v-20.08h8.83c4.38,0,6.73,2.41,6.73,5.51a4.77,4.77,0,0,1-2.92,4.75c1,.37,2.5,1.41,2.5,5v1a15.89,15.89,0,0,0,.29,3.83h-4.12a14.26,14.26,0,0,1-.42-4.51v-.29c0-2.2-.55-3.24-3.79-3.24Zm0-3.45h3.64c2.37,0,3.29-.91,3.29-2.59s-1.07-2.54-3.19-2.54h-3.74Z"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                {SectionHover && false && (
                    <div className="infoSec">
                        {
                            <>
                                {SectionHover.url.trim().toLowerCase() ===
                                    'agotado' && (
                                    <div className="d-flex">
                                        <b className="d-block">AGOTADO</b>
                                    </div>
                                )}
                                <div className="d-flex">
                                    <b className="d-block">Sección: </b>
                                    <span>{SectionHover.name}</span>
                                </div>
                                {moneda === 'USD'
                                    ? SectionHover.preciousd.trim() !== '' && (
                                        <div className="d-flex">
                                            <b className="d-block">
                                                Precio:{' '}
                                            </b>
                                            <span>
                                                desde {SectionHover.preciousd}
                                            </span>
                                        </div>
                                    )
                                    : SectionHover.preciobs.trim() !== '' && (
                                        <div className="d-flex">
                                            <b className="d-block">
                                                Precio:{' '}
                                            </b>
                                            <span>
                                                desde {SectionHover.preciobs}
                                            </span>
                                        </div>
                                    )}
                            </>
                        }
                    </div>
                )}
            </section>
        </section>
    );
};

export default Mapa;

import React from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/dist/sweetalert2.css';
import '../styles/fanbase.scss';
import {
    Footer
} from '../components';

const fanbase = () => {
    let checked = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
        <path d="M17.75 2.66665L7.05 13.6666L0.25 6.66665L2.63333 4.33331L7.05 8.88331L15.3667 0.333313L17.75 2.66665Z" fill="#009FFF"/>
    </svg>;
    let unchecked = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M2.4985 0.166695L0.165169 2.50003L5.63184 8.00003L0.131836 13.5L2.4985 15.8334L7.9985 10.3667L13.4985 15.8667L15.8318 13.5L10.3652 8.00003L15.8652 2.50003L13.4985 0.166695L7.9985 5.63336L2.4985 0.133362V0.166695Z" fill="#8E8E93"/>
    </svg>
    let icon = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
        <path d="M11.3682 2.28306L4.94816 8.88306L0.868164 4.68306L2.29816 3.28306L4.94816 6.01306L9.93816 0.883057L11.3682 2.28306Z" fill="white"/>
    </svg>
    return (
        <section>
            <div className='container'>
                <div className='header_fan'>
                    <img className="rb-img" src={'https://cdn.ticketmundo.live/demo-eu/Rock_Band_logo.png'} alt="rock-band-logo" />
                    <span className='title_fan'>How big of a <span style={{ color: `#8C53F6` }}>fan</span> are you?</span>
                    <p>Joining our fan community  and get  updated with all the latest news! If you're looking for more, subscribe to unlock exclusive content, special deals, and offers—it's our way of rewarding our most dedicated fans</p>
                </div>
                <ul className='pricing_cont'>
                    <li>
                        <div>
                            <span>
                                <p>Follower</p>
                                <h1>FREE</h1>
                            </span>
                            <button className="ld--btn "><span>Comenzar</span></button>
                        </div>
                        <ul>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div>
                            <span>
                                <p>Supporter</p>
                                <h1>$24.95<small>/mes</small></h1>
                                <span>$299.40 / año</span>
                                <span className='ahor'>Se ahorra $60.00 al año</span>
                            </span>
                            <button className="ld--btn "><span>Prueba gratuita por 30 días</span></button>
                        </div>
                        <ul>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div>
                            <span>
                                <p>Groupie</p>
                                <h1>$49.95<small>/mes</small></h1>
                                <span>$299.40 / año</span>
                                <span className='ahor'>Se ahorra $60.00 al año</span>
                            </span>
                            <button className="ld--btn "><span>Prueba gratuita por 30 días</span></button>
                        </div>
                        <ul>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                            <li>
                                {icon} 2 gráficos por pestaña
                            </li>
                        </ul>
                    </li>
                </ul>
                <h2 className='ahor_t'>Compare features per plan</h2>
                <ul className='pricing_cont2'>
                    <li>
                       <div>Feature</div>
                       <ul>
                        <li>2 gráficos por pestaña</li>
                        <li>2 gráficos por pestaña</li>
                        <li>2 gráficos por pestaña</li>
                        <li>2 gráficos por pestaña</li>
                       </ul>
                    </li>
                    <li>
                        <div>Follower</div>
                       <ul>
                        <li>{checked}<span>2 gráficos por pestaña</span></li>
                        <li>{checked}<span>2 gráficos por pestaña</span></li>
                        <li>{checked}<span>2 gráficos por pestaña</span></li>
                        <li>{unchecked}<span>2 gráficos por pestaña</span></li>
                       </ul>
                    </li>
                    <li>
                        <div>Supporter</div>
                       <ul>
                        <li>{checked}<span>2 gráficos por pestaña</span></li>
                        <li>{checked}<span>2 gráficos por pestaña</span></li>
                        <li>{unchecked}<span>2 gráficos por pestaña</span></li>
                        <li>{unchecked}<span>2 gráficos por pestaña</span></li>
                       </ul>
                    </li>
                    <li>
                        <div>Groupie</div>
                       <ul>
                        <li>{checked}<span>2 gráficos por pestaña</span></li>
                        <li>{checked}<span>2 gráficos por pestaña</span></li>
                        <li>{unchecked}<span>2 gráficos por pestaña</span></li>
                        <li>{unchecked}<span>2 gráficos por pestaña</span></li>
                       </ul>
                    </li>
                </ul>
            </div>
            <Footer />
        </section>
    );
};

export default fanbase;

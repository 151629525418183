import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { formatMonto } from '../components/utils';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import { clearCar } from '../store/carSlice';

// markup
const ConfirmarCompra = () => {
    const tickets = useSelector((store) => store.usuario.datos);
    const car = useSelector((store) => store.car.productos);
    const location = useLocation();
    const dispatch = useDispatch();
    const ORDER = location.state;
    console.log(ORDER);

    useLayoutEffect(() => {
        if (!location.state || car.length === 0) {
            document.location.href = '/';
        }
    }, []);
    useEffect(() => {
        dispatch(clearCar());
    }, []);
    return (
        <Layout header={'confirm'}>
            <Seo title={'Shop - Confirmación de Compra'} />
            <div id="pageConfirm">
                <section className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8 mb-lg-0">
                            <div className="infoProductos">
                                <div className="title">Tu orden</div>
                                <div className="productos">
                                    <div className="title-table">
                                        <span></span>
                                        <span>DESCRIPCIÓN</span>
                                        <span>CANTIDAD</span>
                                    </div>
                                    {ORDER &&
                                        ORDER.orden.productos.length > 0 &&
                                        ORDER.car.productos.map((p) => {
                                            let prodTemp =
                                                location.state.orden.productos.find(
                                                    (n) => n.id === p.id
                                                );

                                            return (
                                                <div
                                                    className="itemCar d-flex"
                                                    key={p.id}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <figure>
                                                            <img
                                                                src={
                                                                    prodTemp &&
                                                                    prodTemp
                                                                        .picture_urls[0]
                                                                }
                                                                alt="img prod"
                                                            />
                                                        </figure>
                                                        <div>
                                                            <p className="nombre">
                                                                {prodTemp &&
                                                                    prodTemp.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="d-flex align-items-end flex-column">
                                                            <div className="precio">
                                                                {p.cantidad}
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-5">
                            <div className="title">Método de Envío</div>
                            <div className="funcion">
                                <div className="title mb-4">
                                    Retiro en evento
                                </div>
                                <figure className="mb-4">
                                    <img
                                        src={`${process.env.REACT_APP_URL_IMAGES}banner-desktop.jpg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="title">
                                    {tickets[0].funcion.EventoNombre}
                                </div>
                                <div className="fecha">
                                    {moment(tickets[0].funcion.Fecha).format(
                                        'DD-MM-YYYY HH:mm:ss'
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-5">
                            <div className="title">Nota Importante</div>
                            <div className="nota">
                                <p>
                                    Llegar con al menos X tiempo de anticipación
                                </p>
                                <p>
                                    No es necesario que imprima esta
                                    confirmación, el eticket es el acceso.
                                </p>
                            </div>
                        </div>

                        <div className="col-12 mb-5">
                            <div className="title mb-4">Como llegar?</div>
                            <div className="address">
                                <p>
                                    Av Francisco de Miranda Torre Easo piso 14
                                    ofic CD
                                </p>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.4787077808674!2d-3.708808984603963!3d40.420397679364655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42287cf5838545%3A0xf3f9f62751b4a10d!2zQy8gR3JhbiBWw61hLCA0MSwgMjgwMTMgTWFkcmlkLCBFc3Bhw7Fh!5e0!3m2!1ses-419!2sve!4v1669404919533!5m2!1ses-419!2sve"
                                    width="100%"
                                    height="380px"
                                    style={{ borderRadius: '10px' }}
                                    allowFullScreen=""
                                    loading="lazy"
                                ></iframe>
                            </div>
                        </div>

                        <div className="col-12 mb-5">
                            <div className="title mb-4 text-center">
                                Necesitas ayuda
                            </div>
                            <p className="text-center mb-4">
                                Escríbenos a través de los sigueintes canales{' '}
                            </p>
                            <div className="redes-news w-100 d-flex justify-content-center">
                                <a
                                    href="https://api.whatsapp.com/send?phone=584123604583"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'whatsapp.svg'
                                        }
                                        alt="Whatsapp"
                                    />
                                </a>
                                <a
                                    href="https://telegram.me/ticketmundo_bot"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'telegram.svg'
                                        }
                                        alt="Telegram"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/ticketmundo_ve"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'instagram.svg'
                                        }
                                        alt="Instagram"
                                    />
                                </a>
                                <a
                                    href="https://m.me/ticketmundo"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'messenger.svg'
                                        }
                                        alt="Messenger"
                                    />
                                </a>
                                <a
                                    href={`mailto:atencionalcliente@ticketmundo.com`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'email.svg'
                                        }
                                        alt="Email"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default ConfirmarCompra;

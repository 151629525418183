import React from 'react';
import moment from 'moment';
moment.locale('es');
const Precios = ({ precios, mapaUrl, fees }) => {
    return (
        <section className='ld--wrap-precios'>
            <h1 className='ld--title'>Precios por zona</h1>
            <div className='ld--precios'>
                {mapaUrl && (
                    <div className='ld--mapa ld--card'>
                        <figure>
                            <img
                                src={mapaUrl}
                                alt='mapa precios'
                            />
                        </figure>
                    </div>
                )}
                <div>
                    <div className='ld--detalles ld--card'>
                        {precios.map((p, idx) => (
                            <div className='ld--item-precio' key={idx}>
                                <span
                                    className='ld--color'
                                    style={{ backgroundColor: p.color }}
                                ></span>
                                <div className='ld--info'>
                                    <div className='ld--nombre'>{p.nombre}</div>
                                    <div className='ld--descripcion'>
                                        {p.descripcion}
                                    </div>
                                </div>
                                <div className='ld--precio'>{p.precio}</div>
                            </div>
                        ))}
                    </div>
                    {!fees && (
                        <div className='ld--nota-fees'>
                            Los precios{' '}
                            <strong>no incluyen cargos por servicio.</strong>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Precios;

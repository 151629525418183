import React, { useEffect, useState } from 'react';
import 'react-whatsapp-widget/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
// import { WhatsAppWidget } from 'react-whatsapp-widget';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from './header';
import HeaderConfirm from './header/headerConfirm';
import Footer from './footer';
// import ModalAuth from './login/ModalAuth';
import '../assets/styles/global.scss';
import GeneralSkeleton from './skeletons/general';

const Layout = ({ children, header = 'basic' }) => {
    // const showModalAuth = useSelector((state) => state.usurio.showModalAuth);
    const Shop = useSelector((state) => state.shop);
    const [, setOpen] = useState(true);
    // const date = new Date();
    // const dayWeek = date.getDay();
    // const dispatch = useDispatch();
    useEffect(() => {
        // if (Shop.branches.length === 0) {
        //     dispatch({ type: SHOP_ACTIONTYPES.GET_BRANCHES });
        // } else if (!Shop.branchActive && Shop.branches.length > 0) {
        //     dispatch({
        //         type: SHOP_ACTIONTYPES.SET_BRANCH,
        //         payload: Shop.branches[1].id
        //     });
        // } else {
        //     dispatch({
        //         type: SHOP_ACTIONTYPES.GET_INFO_SHOP,
        //         payload: Shop.branchActive
        //     });
        // }
    }, [Shop.branches, Shop.branchActive]);
    useEffect(() => {
        // let format = 'HH:mm';
        // console.log(Shop);
        if (Shop.branchActive && Shop.infoShop.horarios) {
            // let time = moment(moment(date).format(format), format)
            // let beforeTime = moment(Shop.infoShop.horarios[dayWeek].hour_from, format);
            // let afterTime = moment(Shop.infoShop.horarios[dayWeek].hour_to, format);
            // console.log('time',time.isBetween(beforeTime, afterTime))
            // setOpen(time.isBetween(beforeTime, afterTime))
            setOpen(true);
        }
    }, [Shop.infoShop]);

    return !Shop.branchActive ? (
        <GeneralSkeleton />
    ) : (
        <main
            id="aplicationShop"
            style={{ paddingTop: header === 'confirm' ? 0 : '' }}
        >
            {header === 'confirm' ? <HeaderConfirm /> : <Header />}
            {/* {
          !Open &&
          <div className="cintilloAbierto">
            <div className="container">
              <p>
                El comercio se encuentra <b>cerrado</b>, nuestro horario de atención es de <b>{moment(Shop.infoShop.horarios[dayWeek].hour_from, 'HH:mm').format('hh:mma')} a {moment(Shop.infoShop.horarios[dayWeek].hour_to, 'HH:mm').format('hh:mma')}</b>
              </p>
            </div>
          </div>
        } */}
            <section id="middleLayout">{children}</section>
            <Footer />
            {/* <WhatsAppWidget
                phoneNumber="584122078149"
                textReplyTime="Responderemos en las siguientes 24hrs"
                message={'Hola! 👋🏼 \n\n¿En qué podemos ayudarte?'}
                companyName="Ticketmundo"
                sendButton="Enviar"
                className="btn-wtsp"
            /> */}
            {/* {showModalAuth && <ModalAuth />} */}
            <ToastContainer />
        </main>
    );
};

export default Layout;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CarTop from '../car/carTop';
// import WidgetLogin from '../login/widgetTop';

const Header = (props) => {
    const location = useLocation();
    return (
        <header id="headerPpal" className="justify-content-center">
            <section className="container d-flex justify-content-between align-items-center">
                <Link to="/" className="logo-header">
                    <img src={`${process.env.REACT_APP_URL_IMAGES}logo-tienda.png`} alt="Logo" />
                </Link>
                {!(
                    location.pathname.includes('realizarPago') ||
                    location.pathname.includes('confirmacionCompra')
                ) && (
                    <div className="d-flex align-items-center">
                        <div className="carTopWrap">
                            <CarTop />
                        </div>
                    </div>
                )}
            </section>
        </header>
    );
};

export default Header;

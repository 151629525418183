import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { getComercios, getDetalleComercio } from './services/branch';
import { setShopInformation } from './store/shopSlice';
import Cateogorias from './pages/categorias';
import RealizarPago from './pages/realizarPago';

const Index = () => {
    // const shop = useSelector((state) => state.shop);
    const dispatch = useDispatch();
    useEffect(() => {
        getComercioInfo();
        // console.log(shop);
    }, []);

    const getComercioInfo = async () => {
        const { status, data: branches } = await getComercios();
        if (status === 200) {
            // console.log(branches);
            const infoComercio = await getDetalleComercio(process.env.REACT_APP_SHOP_BRANCH_ID);
            // console.log(infoComercio);
            if (infoComercio) {
                dispatch(
                    setShopInformation({
                        branches: branches.data,
                        branch: infoComercio
                    })
                );
            }
        }
    };

    return (
        <section>
            <Routes>
                <Route path="/categorias" element={<Cateogorias />} />
                <Route path="/realizarPago" element={<RealizarPago />} />
                <Route path="/categorias/:itemid" element={<Cateogorias />} />
            </Routes>
        </section>
    );
};

export default Index;

import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';

import Seo from '../components/seo';
import GridProductos from '../components/producto/gridProductos';
import Layout from '../components/layout';
import {
    getCategoriasPorComercio,
    getProductosPorCatLimit,
    getProductosPorQuery
} from '../services/branch';
import SkeletonProds from '../components/skeletons/grid-prod';
import { useSelector } from 'react-redux';
import ModalProd from '../components/producto/modalProd';
import { useParams } from 'react-router-dom';

const ProductosPorCategoria = () => {
    const shop = useSelector((store) => store.shop);
    const carStore = useSelector((store) => store.car);
    const [Categorias, setCategorias] = useState([]);
    const [CategoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
    const [FilterCategorias, setFilterCategorias] = useState([]);
    const [ProductosF, setProductosF] = useState(null);
    const [Loading, setLoading] = useState(false);
    const controladorTiempo = useRef(null);
    const { itemid } = useParams();
    useEffect(() => {
        if (shop.branchActive) {
            console.log(shop.infoShop.local_currency);
            getCats();
        }
    }, [shop.branchActive]);

    const getCats = async () => {
        let cats = await getCategoriasPorComercio(shop.branchActive);
        // console.log(cats.data.data);
        if (cats.status === 200) {
            setFilterCategorias(cats.data.data);
            setCategorias(cats.data.data);
        }
    };
    useEffect(() => {
        if (CategoriaSeleccionada) {
            setFilterCategorias(
                Categorias.filter((n) => n.id === CategoriaSeleccionada)
            );
        } else {
            setFilterCategorias(Categorias);
        }
    }, [CategoriaSeleccionada]);

    useEffect(() => {
        if (Categorias.length > 0) {
            const find = Categorias.find((n) => {
                console.log(
                    n.name.toLocaleLowerCase(),
                    itemid.replaceAll('-', ' ').toLocaleLowerCase()
                );
                return (
                    n.name.toLocaleLowerCase() ===
                    itemid.replaceAll('-', ' ').toLocaleLowerCase()
                );
            });
            if (find) {
                setCategoriaSeleccionada(find.id);
            }
        }
    }, [Categorias]);

    const inputFilter = (e) => {
        clearTimeout(controladorTiempo.current);
        controladorTiempo.current = setTimeout(() => {
            changeText(e.target.value);
        }, 500);
    };
    const changeText = async (q) => {
        setCategoriaSeleccionada(null);
        if (q.trim() !== '') {
            setLoading(true);
            let prodFilters = await getProductosPorQuery(q, shop.branchActive);
            setLoading(false);
            console.log(prodFilters.data);
            setProductosF(prodFilters.data);
        } else {
            setProductosF(null);
        }
    };
    return (
        <Layout>
            <Seo title={'Shop - Lista de categorias'} />
            <section id="ListaCategorias" className="container paddingY70">
                <div className="topCategorias d-flex justify-content-between">
                    <div className="filters">
                        <button
                            className={
                                CategoriaSeleccionada === null &&
                                ProductosF === null
                                    ? 'active'
                                    : ''
                            }
                            onClick={() => {
                                setProductosF(null);
                                setCategoriaSeleccionada(null);
                            }}
                        >
                            <span>Todas</span>
                        </button>
                        {Categorias.map((c) => (
                            <button
                                key={c.id}
                                className={
                                    CategoriaSeleccionada === c.id
                                        ? 'active'
                                        : ''
                                }
                                onClick={() => {
                                    setProductosF(null);
                                    setCategoriaSeleccionada(c.id);
                                }}
                            >
                                <span>{c.name} </span>
                            </button>
                        ))}
                    </div>
                    <div className="search">
                        <div className="_wrapinput icon-l">
                            <input
                                type="text"
                                placeholder="Buscar Productos"
                                onChange={inputFilter}
                            />
                            <i className="icon-lupa"></i>
                        </div>
                    </div>
                </div>
                {Loading ? (
                    <>
                        <SkeletonProds />
                    </>
                ) : (
                    <>
                        {ProductosF === null ? (
                            FilterCategorias.map((c, idx) => {
                                return (
                                    <Categoria
                                        key={idx}
                                        categoria={c}
                                        branchId={shop.branchActive}
                                    />
                                );
                            })
                        ) : (
                            <GridProductos productos={ProductosF} />
                        )}
                    </>
                )}
            </section>
            {carStore.productoActivo && (
                <ModalProd producto={carStore.productoActivo} />
            )}
        </Layout>
    );
};

export default ProductosPorCategoria;

const Categoria = ({ categoria, branchId }) => {
    const [Productos, setProductos] = useState([]);
    useEffect(() => {
        (async () => {
            let prods = await getProductosPorCatLimit(categoria.id, branchId);
            // console.log(prods)
            if (prods.status === 200) {
                setProductos(prods.data.data);
            }
        })();
    }, [categoria]);

    return (
        <div className="categoriaResumen" key={categoria.id}>
            <div className="top d-flex justify-content-between">
                <h2 className="textTitle">{categoria.name}</h2>
                {/* <Link
                    to={categoria.id.toString()}
                    className="d-flex align-items-center"
                >
                    <span>Ver todos</span>
                    <i className="icon-angle-left reflect ml-2"></i>
                </Link> */}
            </div>
            {<GridProductos productos={Productos} />}
            <div className="mb-3" />
        </div>
    );
};
